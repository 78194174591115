<template>
	<b-modal
		title="Payment Details"
		size="lg"
		centered
		hide-footer
		v-model="isActive"
		@hidden="handleHidden"
	>
		<template v-if="type == 'card'">
			<b-row>
				<b-col>
					<b-form-group label="Card name">
						<b-form-input v-model="payment.cardholdername" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col md="8">
					<b-form-group label="Card number">
						<b-form-input v-model="payment.cardnumber" disabled />
					</b-form-group>
				</b-col>
				<b-col md="4">
					<b-form-group label="Card Cvv">
						<b-form-input v-model="payment.cvv_card" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group label="Expiration Month">
						<b-form-input v-model="payment.card_expi_month" disabled />
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Expiration Year">
						<b-form-input v-model="payment.card_expi_year" disabled />
					</b-form-group>
				</b-col>
			</b-row>
		</template>
		<template v-else>
			<b-row>
				<b-col>
					<b-form-group label="Account Name">
						<b-form-input v-model="payment.account_name" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group label="Routing Name">
						<b-form-input v-model="payment.routing_name" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group label="User Name">
						<b-form-input v-model="payment.username" disabled />
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-group label="Address">
						<b-form-input v-model="payment.address" disabled />
					</b-form-group>
				</b-col>
			</b-row>
		</template>
	</b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import expensesService from "../expenses.service"

export default {
	props: {
		show: Boolean,
		type: String,
		id: Number,
	},
	data() {
		return {
			isActive: false,
			payment: {},
		}
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.$emit("closing")
		},
		async getCardDetails() {
			this.addPreloader();
			let res = await expensesService.observeCard(
				this.id,
				this.currentUser.user_id
			)
			this.payment = res[0]
			this.removePreloader();
		},
		async getCheckDetails() {
			this.addPreloader();
			let res = await expensesService.observeCheck(
				this.id,
				this.currentUser.user_id
			)
			this.payment = res[0]
			this.removePreloader();
		},
	},
	computed: { ...mapGetters({ currentUser: "auth/currentUser" }) },
	watch: {
		show: function (val) {
			if (val) {
				if (this.type == "card") {
					this.getCardDetails()
				} else {
					this.getCheckDetails()
				}
				this.isActive = true
			}
		},
	},
}
</script>
