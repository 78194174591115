<template>
  <b-modal
    title="TRACKING"
    size="lg"
    modal
    centered
    title-class="h4 text-white font-weight-bolder"
    header-class="class_modal_js"
    v-model="isActive"
    @hidden="handleHidden"
  >
    <b-row>
      <b-col>
        <b-table ref="tracking-table" small :items="tableProvider">
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(date)="data">
            <span>{{ data.value | myGlobalDay }}</span>
          </template>
          <template #cell(action)="data">
            <b-badge :variant="getBadgeColor(data.value)">
              {{ data.value }}
            </b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import expensesService from "../expenses.service";

export default {
  props: {
    show: Boolean,
    type: String,
    id: Number,
  },
  data() {
    return {
      isActive: false,
      fields: [
        {
          key: "account",
          label: "Account",
        },
      ],
    };
  },
  methods: {
    handleHidden() {
      this.isActive = false;
      this.$emit("closing");
    },
    async tableProvider() {
      let res;
      if (this.type == "card") {
        res = await expensesService.getTrackingCard(this.id);
      } else if (this.type == "check") {
        res = await expensesService.getTrackingCheck(this.id);
      } else {
        res = await expensesService.getTrackingExpense(this.id);
      }

      if (this.type != "card" || this.type != "check") {
        return res.map((track) => ({
          date: track.created_at,
          action: track.status_action,
          detail: track.columns_update,
          user: track.created_by_user,
        }));
      }

      return res.map((track) => ({
        date: track.created_at,
        action: track.status_action,
        user: track.created_by_user,
      }));
    },
    getBadgeColor(action) {
      switch (action) {
        case "UPDATED":
          return "light-primary";
          break;
        case "OBSERVED":
          return "light-warning";
          break;
        case "CREATED":
          return "light-success";
          break;
        case "DELETED":
          return "light-danger";
          break;
        default:
          return "light-secondary";
          break;
      }
    },
  },
  watch: {
    show: function (val) {
      if (val) {
        this.isActive = true;
      }
    },
  },
};
</script>
